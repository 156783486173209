<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login -->
      <b-card class="mb-0">
        <image-logo />

        <b-card-title class="mb-2 text-center">
          Cadastro plataforma CUIDO
        </b-card-title>
        <b-card-text class="mb-2 text-center">
          Agora digite seu nome completo:
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="registerName"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="name"
              label="Digite aqui seu nome"
            >
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="registerName"
                  name="register-name"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              class="mt-2"
              :disabled="invalid"
              @click="validationForm"
            >
              CONTINUAR
            </b-button>

            <div class="mt-2 text-center">
              <b-link
                :to="{name:'register-email'}"
              >
                <u>VOLTAR</u>
              </b-link>
            </div>
          </b-form>
        </validation-observer>
        <b-progress
          value="42.87"
          max="100"
          class="progress-bar-primary mt-2"
          variant="primary"
        />
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BProgress,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ImageLogo from '@/layouts/components/ImageLogo.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BProgress,
    BCardText,
    ImageLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      registerName: '',
      // validation rules
      required,
    }
  },

  mounted() {
    localize(this.$store.getters['cuido/locale'])
    this.registerName = this.$store.state.register.name
  },

  methods: {
    validationForm() {
      this.$refs.registerName.validate().then(success => {
        if (success) {
          this.$store.commit('register/UPDATE_NAME', this.registerName)
          this.$router.push('/register-board')
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
  .card-body {
    padding-bottom: 1px;
}
</style>
